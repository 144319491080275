import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class About extends Component {
  render() {
    return (
      <Row>
        {this.props.values.header ?
          <Col xs={{ size: "12" }}>
            <h2>
              {this.props.values.header}
            </h2>
          </Col>
        : null }
        <Col xs={{ size: "12" }}>
          {this.props.values.paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
          <ul>
            {this.props.values.bullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
          </ul>
        </Col>
      </Row>
    );
  }
}

export default About;
