import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class Name extends Component {
  render() {
    return (
      <Row>
        <Col xs={{ size: "12" }}>
          <h1>
            {this.props.name}
          </h1>
        </Col>
      </Row>
    );
  }
}

export default Name;
