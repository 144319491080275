import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';

class Education extends Component {
  render() {
    if (typeof this.props.values !== 'undefined') {
      return (
        <Row className={classNames('d-print-none')}>
          <Col xs={{ size: "12" }}>
            <h2>
              {this.props.values.header}
            </h2>
          </Col>
          {this.props.values.educationEntries.map((entry, index) => 
            <Col key={index} xs={{ size: "12" }}>
              <Row>
                <Col xs={{ size: "12" }}>
                  <h3 className={classNames('d-flex', 'flex-column', 'flex-sm-row', 'align-items-start', 'align-items-sm-end', 'justify-content-between')}>
                    <span>{entry.header}</span>
                  </h3>
                  <h4 className={classNames('d-flex', 'flex-column', 'flex-sm-row', 'align-items-start', 'align-items-sm-end', 'justify-content-between')}>
                    <span>{entry.program}</span><small>{entry.timeline}</small>
                  </h4>
                </Col>
              </Row>
              <Row key={index}>
                <Col xs={{ size: "12" }}>
                  {entry.paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
                  <ul>
                    {entry.bullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                  </ul>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      );
    }
  }
}

export default Education;
