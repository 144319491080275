import React, { Component } from 'react'; 
import { Container, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-regular-svg-icons'

import Information from './components/Information';
import Portfolio from './components/Portfolio';

import styles from './App.module.css';

import background from './background.png';

// https://stackoverflow.com/a/24181701
function calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function taunt() {
  switch (Math.floor(Math.random() * 10)) {
    case 0:
      console.log('go fuck yourself');
      break;
    case 1:
      console.log('i hope you fucking die');
      break;
    case 2:
      console.log('kill yourself');
      break;
    case 3:
      console.log('fuck you');
      break;
    case 4:
      console.log('fuck off');
      break; case 5:
      console.log('die');
      break;
    case 6:
      console.log('die now');
      break;
    case 7:
      console.log('fucking die');
      break;
    case 8:
      console.log('fuck off and die already');
      break;
    case 9:
      console.log('oh no oh ya got me');
      break;
    default:
      console.log('...');
      break;
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Colin A. Shaw",
      aboutValues: {
        //header: "Summary",
        paragraphs: [
          //`
          //  I believe that any problem can be solved with 
          //  carefully constructed code, and that pride of 
          //  craftsmanship is apparent when 
          //  software solutions are elegant, efficient, and robust.
          //  The pursuit of these three ideals is what drives my development process, 
          //  a process that is continually refined to keep pace with technology's rapid evolution.
          //`,
          //`
          //  As a software developer and solutions architect I'm excited at the prospect of 
          //  being a part of delivering impactful products and transformative experiences.
          //`,
        ],
        bullets: [
          `Software developer with ${calculateAge(new Date(2015, 7))} years of experience.`, // GeneYouIn start - July 2015.
        ],
      },
      contactValues: {
        //mobile: "(647) 786-9056",
        email: "me@colinalexandershaw.com",
        website: "https://colinalexandershaw.com",
      },
      //techHighlights: {
      //  header: "Technology Highlights",
      //  technologies: {
      //    "Front-end": [
      //      "JavaScript",
      //      "React",
      //      "Bootstrap",
      //    ],
      //    "Back-end": [
      //      "Python",
      //      "Flask",
      //      "SQLAlchemy",
      //      "Celery",
      //    ],
      //    "Operations": [
      //      "Ansible",
      //      "Docker",
      //      "Selenium",
      //      "ELK",
      //    ],
      //  }
      //},
      experienceValues: {
        //header: "Experience",
        experienceEntries: [
          {
            header: "Oracle",
            link: null,
            position: "Software Developer, DBaaS",
            //timeline: "Aug 2020 - Present",
            paragraphs: [
            ],
            bullets: [
              //"Developing scripts to automate the building and provisioning of service resources hosted on cloud infrastructure.",
              //"Designing dashboards to breakdown and highlight performance metrics.",
              //"Automating reports on the health and reliability of platform services.",
              //"Collaborating across teams to understand and improve the developer experience.",
              //"Resolving pipeline bottlenecks by prioritizing automation efforts.",
              //"Configuring GitLab pipelines that handle Kubernetes artifact deployment.",
              //"Accountable for",
              //"Resolved",
              //"Presided",
              //"Prioritized",
              //"Advocated",
              //"Guided",
            ],
          },
          //{
          //  header: "Pillcheck",
          //  link: null,
          //  position: "Web Developer",
          //  timeline: "Jul 2015 - Aug 2020",
          //  paragraphs: [
          //  ],
          //  bullets: [
          //    //"Met with managers and members of the executive team to collect and refine development requirements.",
          //    //"Coordinated product development using Agile methodology.",
          //    //"Communicated technical requirements to the development team.",
          //    //"Worked with the bioinformatics team to implement algorithms for generating reports.",
          //    //"Incorporated existing analytic toolchains into production environments.",
          //    //"Designed and developed tools to enable healthcare practitioners to collaborate on report data.",
          //    //"Maintained a suite of unit and integration tests.",
          //    //"Augmented user experience by creating web applets to collect data and improve service.",
          //    //"Wrote and maintained user guides and technical documentation.",
          //    //"Provided production support to triage and remediate customer issues.",
          //    //"Utilized Alembic to coordinate database migration efforts and maintain data integrity.",
                //"Cultivating an ecosystem of microservices in accordance with GitDevSecOps best practices.",
                //"Leveraged Celery and Redis to stabilize analytical pipelines and improve service reliability.",
          //  ],
          //},
        ]
      },
      projectValues: {
        header: "Projects",
        projectEntries: [
          {
            header: "Image Sharing Platform",
            link: "https://gitlab.com/colinshaw/image-site",
            paragraphs: [
              "A simple web log application framework.",
            ],
            bullets: [
              //"User registration and authentication.",
              //"Users can create posts and upload image files.",
              //"User commenting system.",
            ],
          },
        ]
      },
      //educationValues: {
      //  header: "Education",
      //  educationEntries: [
      //    {
      //      header: "Toronto Metropolitan University",
      //      program: "Certificate, Programming Applications",
      //      timeline: "Jan 2013 - Apr 2015",
      //      paragraphs: [],
      //      bullets: [
      //        //"Utilized Java to develop a puzzle game.",
      //        //"Designed software in C++ to run simulations and numerical data analysis.",
      //        //"Wrote Bash scripts to process text using regular expressions.",
      //        //"Linux server administration.",
      //        //"Used C to implement searching and sorting algorithms.",
      //      ]
      //    }
      //  ]
      //},
      backgroundPlaying: false,
    }
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      let pathname = window.location.pathname;
      if (pathname !== '/') {
        fetch(`${pathname}.json`)
          .then(
            (response) => {
              if (!response.ok || !response.headers.get('content-type').includes('application/json')) {
                taunt()
                return;
              }
              return response.json();
            },
            (error) => {
              taunt()
            }
          )
          .then(
            (json) => {
              if (typeof json !== 'undefined') {
                let new_state = this.state;
                new_state['aboutValues']['bullets'].push(...json['aboutValues']['bullets']);
                new_state['contactValues']['mobile'] = json['contactValues']['mobile'];
                new_state['experienceValues']['header'] = json['experienceValues']['header'];
                new_state['experienceValues']['experienceEntries'][0]['timeline'] = json['experienceValues']['experienceEntries'][0]['timeline'];
                new_state['experienceValues']['experienceEntries'][0]['bullets'].push(...json['experienceValues']['experienceEntries'][0]['bullets']);
                new_state['experienceValues']['experienceEntries'].push(json['experienceValues']['experienceEntries'][1]);
                new_state['educationValues'] = json['educationValues'];
                this.setState(new_state);
              }
            }
          )
      }
    }
  }
  render() {
    return (
      <>
        <Container fluid>
          <Row className={classNames(styles.copyContainer)}>
            <Col>
              <Row>
                <span>
                  <FontAwesomeIcon 
                    className={classNames(
                      styles.videoButton,
                      'ms-2',
                      'mb-2',
                      'd-none',
                      'd-lg-flex',
                    )}
                    onClick={event => {
                      if (this.state.backgroundPlaying) {
                        this.refs.backgroundVideo.pause();
                        this.setState({backgroundPlaying: false});
                      } else {
                        this.refs.backgroundVideo.play();
                        this.setState({backgroundPlaying: true});
                      }
                    }}
                    icon={this.state.backgroundPlaying ? faPauseCircle : faPlayCircle} 
                  />
                </span>
              </Row>
            </Col>
            <Col 
              xs={{ size: 12 }} 
              sm={{ size: 12 }} 
              lg={{ size: 6, offset: 6 }} 
              xl={{ size: 5, offset: 7 }}
              className={classNames(styles.copy)}
            >
              <Row>
                <Information 
                  name={this.state.name} 
                  contactValues={this.state.contactValues} 
                  aboutValues={this.state.aboutValues}
                  //techValues={this.state.techHighlights}
                />
                <Portfolio 
                  experienceValues={this.state.experienceValues} 
                  projectValues={this.state.projectValues}
                  educationValues={this.state.educationValues}
                />
              </Row>
            </Col>
          </Row>
        </Container>
        <video
          ref='backgroundVideo'
          //autoPlay
          muted
          loop
          poster={background}
          className={classNames(styles.video, 'd-none', 'd-lg-block')}
        >
          <source src='background.mp4' type='video/mp4'/>
        </video>
      </>
    );
  }
}

export default App;
