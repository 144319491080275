import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Name from './Name.js';
import Contact from './Contact';
import About from './About';

import styles from './Information.module.css';

class Information extends Component {
  renderCategory(category) {
    return (
      <Col xs={{ size: 12 }}>
        <h3 className="mb-0">
          {category}
        </h3>
      </Col>
    );
  }
  renderItems(items) {
    let techItems = [];
    for (let i = 0; i <= items.length; i++) {
      techItems.push(<span className="pe-0 pe-sm-1">{items[i]}</span>);
      if (techItems.length <= items.length + 1) {
        techItems.push(<span className="d-sm-none pe-1 pe-sm-0">,</span>);
      }
    }
    return (
      <Col xs={{ size: 12 }} className="d-flex flex-wrap flex-row flex-sm-column mb-1">
        {techItems}
      </Col>
    );
  }
  renderTechnologies(technologies) {
    return (
      Object.keys(technologies).map((category, index) => {
        return (
          <Col key={index} xs={{ size: 12 }} sm={{ size: 4 }}>
            <Row>
              {this.renderCategory(category)}
              {this.renderItems(technologies[category])}
            </Row>
          </Col>
        );
      })
    );
  }
  render() {
    return (
      <Col xs={{ size: "12" }} className={[styles.information, 'pt-2'].join(' ')}>
        <Name name={this.props.name}/>
        <Contact values={this.props.contactValues}/>
        <About values={this.props.aboutValues}/>
        {/*
        <Row>
          <Col xs={{ size: "12" }}>
            <h2>
              {this.props.techValues.header}
            </h2>
          </Col>
          <Col xs={{ size: "12" }}>
            <Row>
              {this.renderTechnologies(this.props.techValues.technologies)}
            </Row>
          </Col>
        </Row>
        */}
      </Col>
    );
  }
}

export default Information;
