import React, { Component } from 'react';
import { Col } from 'reactstrap';
import classNames from 'classnames';
import Experience from './Experience';
import Projects from './Projects';
import Education from './Education';

import styles from './Portfolio.module.css';

class Portfolio extends Component {
  render() {
    return (
      <Col xs={{ size: "12" }} className={classNames(styles.portfolio, 'pt-3')}>
        <Experience values={this.props.experienceValues}/>
        <Projects values={this.props.projectValues}/>
        <Education values={this.props.educationValues}/>
      </Col>
    );
  }
}

export default Portfolio;
