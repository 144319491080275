import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'

class Contact extends Component {
  render() {
    return (
      <Row>
        <Col xs={{ size: "12" }}>
          <p>
            <span className={classNames('d-none', 'd-print-inline-flex')}>
              {/* Remove all whitespaces and non-alphanumeric characters from number for link tel value. */}
              { this.props.values.mobile ? (
                <>
                  <a href={`tel:+{/*Canada country code*/}1${this.props.values.mobile.replace(/\s/g, '').replace(/\W/g, '')}`} className={classNames('me-3')}> 
                    {this.props.values.mobile}
                  </a>
                  <span className={classNames('me-3')}>|</span>
                </>
              ) : null }
            </span>
            <a href={`mailto:${this.props.values.email}`}>
              {this.props.values.email}
              <FontAwesomeIcon className={classNames('d-print-none', 'ms-2')} icon={faEnvelopeSquare} size='sm' />
            </a>
            <span className={classNames('d-none', 'd-print-inline-flex')}>
              <span className={classNames('ms-3')}>|</span>
              <a href={`${this.props.values.website}`} target='_blank' rel='noopener noreferrer' className={classNames('ms-3')}> 
                {this.props.values.website}
              </a>
            </span>
          </p>
        </Col>
      </Row>
    );
  }
}

export default Contact;
